<template>
  <div class="lastBox">

    <div class="banner"></div>
    <div class="banner-header">
      <p class="banner-header-font">
        汽车报废指南
      </p>
      <div class="tem-flex">
        <div class="model-header">
          <div>
            <img class="model-img" src="/img/zhinan1.png" alt="" />
          </div>
          <div class="model-right-top">
            <p class="model-right-font-1">车主注册</p>
            <p class="model-right-font-2">
              陕西报废平台账户或扫描小程序登录
            </p>
            <img class="model-right-img" src="/img/1.png" alt="" />
          </div>
        </div>
        <div class="model-header">
          <div>
            <img class="model-img" src="/img/zhinan2.png" alt="" />
          </div>
          <div class="model-right-top">
            <p class="model-right-font-1">申请报废</p>
            <p class="model-right-font-2">
              上传车辆信息提交报废申请
            </p>
            <img class="model-right-img" src="/img/2.png" alt="" />
          </div>
        </div>
        <div class="model-header">
          <div>
            <img class="model-img" src="/img/zhinan3.png" alt="" />
          </div>
          <div class="model-right-top">
            <p class="model-right-font-1">预约拖车</p>
            <p class="model-right-font-2">预约拖车或自送</p>
            <img class="model-right-img" src="/img/3.png" alt="" />
          </div>
        </div>
        <div class="model-header">
          <div>
            <img class="model-img" src="/img/zhinan4.png" alt="" />
          </div>
          <div class="model-right-top">
            <p class="model-right-font-1">验车</p>
            <p class="model-right-font-2">
              车辆托运进拆解厂验车组完成验车检查车辆基本情况
            </p>
            <img class="model-right-img" src="/img/4.png" alt="" />
          </div>
        </div>
        <div class="model-header">
          <div>
            <img class="model-img" src="/img/zhinan5.png" alt="" />
          </div>
          <div class="model-right-top">
            <p class="model-right-font-1">评估残值</p>
            <p class="model-right-font-2">过磅评估车辆残值</p>
            <img class="model-right-img" src="/img/5.png" alt="" />
          </div>
        </div>
        <div class="model-header">
          <div>
            <img class="model-img" src="/img/zhinan6.png" alt="" />
          </div>
          <div class="model-right-top">
            <p class="model-right-font-1">业务办理</p>
            <p class="model-right-font-2">业务大厅办理业务</p>
            <img class="model-right-img" src="/img/6.png" alt="" />
          </div>
        </div>
        <div class="model-header">
          <div>
            <img class="model-img" src="/img/zhinan7.png" alt="" />
          </div>
          <div class="model-right-top">
            <p class="model-right-font-1">残值结算</p>
            <p class="model-right-font-2">等待残值结算</p>
            <img class="model-right-img" src="/img/7.png" alt="" />
          </div>
        </div>
        <div class="model-header">
          <div>
            <img class="model-img" src="/img/zhinan8.png" alt="" />
          </div>
          <div class="model-right-top">
            <p class="model-right-font-1">支付完成</p>
            <p class="model-right-font-2">
              残值支付完成车主可查询到残值获取信息
            </p>
            <img class="model-right-img" src="/img/8.png" alt="" />
          </div>
        </div>
      </div>
      <div class="bottom-body">
        <el-button :disabled="time > 5" class="bottom-btn-left" size="small">已阅读
          <span v-if="time <= 10">{{ time }}秒</span>
        </el-button>
        <el-button @click="handleCancel" class="bottom-btn-right" size="small">取消</el-button>
      </div>
    </div>

  </div>
</template>

<script>
import { createUserVisitLog } from "../../api/personalCenter";
import { getToken } from "@/utils/cookies";
export default {
  name: "adressMaintain",
  data() {
    return {
      time: 10,
      timer: null,
    };
  },
  watch: {
    $route: (to, form) => {
      console.log(to.path);
    },
  },
  mounted() {
    this.timer = setInterval(this.countDown, 1000);
  },
  beforeRouteLeave(to, from, next) {
    if (to.path !== from.path) {
      clearInterval(this.timer);
    }
    next();
  },
  methods: {
    countDown() {
      if (this.time <= 0) {
        this.time = 0;
        clearInterval(this.timer);
        this.finishRead();
      } else {
        if(this.time === 5 && !getToken()){
          this.$message.warning("即将跳入登录页");
        }
        this.time--;
      }
    },
    async finishRead() {
      try {
        const res = await createUserVisitLog();
        this.$router.push("/scrap/firstScrap");
      } catch (error) {
        console.error(error);
      }
    },
    handleCancel() {
      this.$router.back();
    },
  },
};
</script>

<style lang="less" scoped>
.banner {
  text-align: center;
  height: 480px; /* no */
  background-image: url(/img/zhinanbg.png);
  background-size: 100%;
  line-height: 480px; /* no */
}
.banner-header {
  background: white;
  padding: 0 17px 50px 20px; /* no */
  width: 62.5%;
  margin: 0 auto;
  margin-bottom: 50px; /* no */
  margin-top: 28px; /* no */
}
.banner-header-font {
  font-size: 24px; /* no */
  text-align: center;
  padding: 49px 0 28px 0; /* no */
}
.tem-flex {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.tem-flex:after {
  content: "";
  width: 30%;
  border: 1px solid transparent; /* no */
}
.model-header {
  display: flex;
  width: 372px; /* no */
  margin-bottom: 20px; /* no */
}
.model-img {
  width: 100%;
  height: 100%;
}
.model-right-top {
  width: 115px; /* no */
  padding: 19px 60px 0 21px; /* no */
  border: 1px solid #eeeeee; /* no */
  position: relative;
}
.model-right-img {
  position: absolute;
  right: 0;
  top: 0;
  width: 49px; /* no */
  height: 48px; /* no */
}
.model-right-font-1 {
  font-size: 18px; /* no */
  margin-bottom: 19px; /* no */
}
.model-right-font-2 {
  font-size: 14px; /* no */
  line-height: 30px; /* no */
}
.bottom-body {
  text-align: center;
  margin-top: 10px; /* no */
}
.bottom-btn-left {
  width: 160px; /* no */
  height: 48px; /* no */
  background: #24a2aa;
  color: white;
  font-size: 16px; /* no */
  &.is-disabled{
    background-color: #EBEEF5;
    color: #C0C4CC;
  }
}
.bottom-btn-right {
  width: 160px; /* no */
  height: 48px; /* no */
  color: #24a2aa;
  font-size: 16px; /* no */
  border: solid 1px #24a2aa; /* no */
  margin-left: 30px; /* no */
}
</style>
